import {createContext, useState, useEffect} from 'react';
import ProductsAPI from './api/ProductsAPI';
import BannersAPI from './api/bannersAPI';
import UserAPI from './api/UserAPI';
import CategoriesAPI from './api/CategoriesAPI';
import axios from 'axios';

export const GlobalState = createContext();

export const DataProvider = ({children}) => {
    const [token, setToken] = useState(false);

    const [size, setSize] = useState("");
    const [color, setColor] = useState("");
    const [withoutРegistration, setWithoutRegistration] = useState(true);
    const [cities, setCities] = useState([]);

    const options = [];
    cities.slice(0, 6000).map((city, index) => (
        city.zoneName === "Зона А" ||  city.zoneName === "Зона В"?
            // <option value={city.id} key={index}>{city.name}</option> 

            options.push({ value: city.id, label: city.name}) : null

    ));

    console.log(options.length)

    useEffect(() => {
        const func = async () => {
            try {
                const body = {
                    // Тук добавете необходимите данни за тялото на заявката
                    countryCode: "BGR", // Примерно поле
                };
    
                const response = await axios.post(
                    `https://demo.econt.com/ee/services/Nomenclatures/NomenclaturesService.getCities.json`, 
                    body, // Тялото на заявката
                    { 
                        headers: { 
                            "Authorization": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJUb3BsaXZvIiwibmFtZSI6Ik5zeXMiLCJpYXQiOjE3NTE5MjI2MH0.pIieD99VFE1k2BclxsboPhdY_M8o11kzngmNCbYi5Vc`,
                            "Content-Type": "application/json" // Добавете този header, ако body-то е JSON
                        } 
                    }
                );
    
                if(response.status === 200){
                    setCities(response.data.cities);
                }
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        };
        func();
    }, []);

    useEffect(() => {
        const firstLogin = localStorage.getItem('firstLogin');

        if(firstLogin) {
            const refreshToken = async () => {
                const res = await axios.get('/user/refresh_token');
        
                setToken(res.data.accesstoken);
                setWithoutRegistration(false);
    
                setTimeout(() => {
                    refreshToken();
                }, 10 * 60 * 1000)
            }
                refreshToken()
        }

    }, [])

        const state = {
            token: [token, setToken],
            productsAPI: ProductsAPI(),
            bannersAPI: BannersAPI(),
            userAPI: UserAPI(token),
            categoriesAPI: CategoriesAPI(),
            size: [size, setSize],
            color: [color, setColor],
            withoutRegistration: [withoutРegistration, setWithoutRegistration],
            cities: [cities],
            options1: [options]
        }

    return(
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}