import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ReactPixel from 'react-facebook-pixel';



import { DataProvider } from './GlobalState';
import Header from './components/Headers/Header';
import Pages from './components/MainPages/Pages';
import Footer from './components/Footer/Footer'
import ScrollToTop from './components/ScrollToTop'

function App() {

  // const options = {
  //   autoConfig: true, // автоматична конфигурация
  //   debug: true,      // за дебъгване
  // };
  
  // const pixelId = '360963908234784';
  // ReactPixel.init(pixelId, {}, options);
  // ReactPixel.pageView(); // Отчита зареждане на страница


  return (
    <DataProvider>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Header />
          <Pages />
          <Footer />
        </div>
        <MessengerCustomerChat
            pageId="111707133943126"
            appId="1010702149773780"
          />,
      </Router>
    </DataProvider>
  );
}

export default App;
